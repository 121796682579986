import React, { useState, useEffect, useRef, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { toUnicode } from 'punycode'
import { List } from 'react-virtualized'
import { useOutsideClick } from '../hooks'
import { WithTooltip } from '../elements'
import { map, varClass, isBlank } from '../helpers'
import { TTimeout } from '../types'

const showSearchThreshold = 10
// const hideValuesThreshold = 40

const shouldShowRemove = (item, hideRemove, hideRemoveOnlyWithValue) => {
  if (hideRemoveOnlyWithValue && !item.value) return false
  if (hideRemove === true) return false
  if (hideRemove && Array.isArray(hideRemove)) {
    return !hideRemove.includes(item.key)
  }
  return true
}

const formatFilterLabel = (record, label) => {
  if (typeof label === 'function') {
    return label(record)
  } else {
    return record[label]
  }
}
export const formatFilterRecords = (records, value, label1, label2?) => {
  if (!records || isBlank(records)) {
    return {}
  } else {
    return records.reduce((result, record) => {
      result[record[value]] =
        formatFilterLabel(record, label1) ||
        formatFilterLabel(record, label2) ||
        'Unknown'
      return result
    }, {})
  }
}

export const NavFilter = (props) => {
  const {
    filters,
    setFilters,
    filterLabels,
    filterOptions,
    filtersToKeep,
    hideAdd,
    hideRemove,
    hideRemoveOnlyWithValue,
    hideChecks,
    hideNoValue,
    radio,
    isMultipleChoice,
    noMultipleChoice,
  } = props

  const [showClearButton, setShowClearButton] = useState(false)

  useEffect(() => {
    const hasFilter = filters.some((item) => item.value)
    setShowClearButton(hasFilter && isMultipleChoice)
  }, [filters])

  const onSelect =
    props.onSelect ||
    ((key, value, label) => {
      const newFilters = [...filters]
      const filter = newFilters.find((filter) => filter.key === key)
      if (isMultipleChoice && !noMultipleChoice?.includes(filter.key)) {
        if (filter.value) {
          const splitValue = filter.value.split(', ')
          const splitLabel = (filter.label || '').split(', ')
          const existValue = splitValue.find((v) => v === value)
          if (existValue) {
            filter.value = splitValue.filter((v) => v !== value).join(', ')
            filter.label = splitLabel.filter((l) => l !== label).join(', ')
          } else {
            filter.value += `, ${value}`
            filter.label += `, ${label}`
          }
        } else {
          filter.value = value
          filter.label = label
        }

        if (!value || label === 'All') {
          filter.label = label
          filter.value = value
        }
      } else {
        filter.value = value
        filter.label = label
        if (filter.value === '') filter.label = ''
      }

      setFilters(newFilters)
    })

  const onRemove =
    props.onRemove ||
    ((index, key) => {
      const newFilters = [...filters]
      if (filtersToKeep && filtersToKeep.includes(key)) {
        const filter = newFilters.find((filter) => filter.key === key)
        filter.value = ''
        filter.label = ''
      } else {
        newFilters.splice(index, 1)
      }
      setFilters(newFilters)
    })

  const onAdd =
    props.onAdd ||
    ((key) => {
      const newFilters = [...filters]
      if (!newFilters.find((filter) => filter.key === key)) {
        newFilters.push({
          key: key,
          value: '', // TODO: Change to array when multi-filter is supported
          label: '',
        })
        setFilters(newFilters)
      }
    })

  const onClear = () => {
    const clearFilter = filters.map((f) => ({ ...f, value: '', label: '' }))
    setFilters(clearFilter)
  }

  return (
    <div
      className={varClass({
        'nav-filters': true,
        'nav-filters-no-checks': hideChecks,
        'nav-filters-radio': radio,
      })}
    >
      {map(filters, (item, index) => {
        switch (filterOptions[item.key]) {
          case 'input':
            return (
              <NavInput
                key={item.key}
                {...{
                  index,
                  item,
                  filterLabels,
                  filterOptions,
                  onSelect,
                  onRemove,
                  hideRemove,
                  hideRemoveOnlyWithValue,
                  isMultipleChoice,
                }}
              />
            )
          case 'pair':
            return (
              <NavPair
                key={item.key}
                {...{
                  index,
                  item,
                  filterLabels,
                  filterOptions,
                  onSelect,
                  onRemove,
                  hideRemove,
                  hideRemoveOnlyWithValue,
                  isMultipleChoice,
                }}
              />
            )
          default:
            return (
              <NavSelect
                key={item.key}
                {...{
                  index,
                  item,
                  filterLabels,
                  filterOptions,
                  onSelect,
                  onRemove,
                  hideRemove,
                  hideRemoveOnlyWithValue,
                  hideNoValue,
                  isMultipleChoice,
                }}
              />
            )
        }
      })}

      {!hideAdd && (
        <NavAdd
          {...{
            filterLabels,
            filterOptions,
          }}
          onAdd={onAdd}
        />
      )}
      {showClearButton && <NavClearFilter {...{ onClear }} />}
    </div>
  )
}

const NavSelect = (props) => {
  const {
    index,
    item,
    filterLabels,
    filterOptions,
    onSelect,
    onRemove,
    hideRemove,
    hideRemoveOnlyWithValue,
    hideNoValue,
    isMultipleChoice,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [search, setSearch] = useState('')
  const options = useMemo(() => {
    if (filterOptions[item.key]) {
      return filterOptions[item.key]
    } else {
      return {}
    }
  }, [item.key, filterOptions])

  const hasOptions = !isBlank(options)
  const hasValue = item.value || item.label

  const showSearch = Object.keys(options).length > showSearchThreshold
  const shownOptions = useMemo(() => {
    if (search) {
      return Object.keys(options).reduce((result, option) => {
        if (options[option].toLowerCase().indexOf(search.toLowerCase()) !== -1)
          result[option] = options[option]
        return result
      }, {})
    } else {
      return options
    }
  }, [options, search])
  const shownOptionKeys = Object.keys(shownOptions)
  const showRemove = shouldShowRemove(item, hideRemove, hideRemoveOnlyWithValue)

  const listHeight = Object.keys(options).length * 33 + 12

  return (
    <div className="nav-filter" ref={ref}>
      <button
        className={varClass({
          'nav-filter-button': true,
          [`nav-filter-button-${item.key}`]: true,
          'open': open,
          'active': !!hasValue,
          'p-r-3': !hasOptions,
        })}
        onClick={() => setOpen(!open)}
      >
        {!hideNoValue || hasValue ? (
          <>
            {filterLabels[item.key]}:{' '}
            <b>
              {hasValue ? (
                <WithTooltip
                  className="nav-filter-value"
                  label={item.label || options[item.value] || item.value}
                  text={item.label || options[item.value] || item.value}
                />
              ) : (
                'All'
              )}
            </b>
          </>
        ) : (
          <>{filterLabels[item.key]}</>
        )}
        {hasOptions && (
          <SVG
            src="/images/chevron-down.svg"
            className={varClass({
              'm-l-15': true,
              'rotate-180': open,
            })}
          />
        )}
      </button>

      {hasValue && showRemove && (
        <button
          className="nav-filter-remove"
          onClick={() => {
            onRemove(index, item.key)
            setOpen(false)
          }}
        >
          <SVG src="/images/insights/icon-trash.svg" />
        </button>
      )}

      {hasOptions && (
        <div
          className={varClass({
            'nav-filter-menu': true,
            'open': open,
          })}
        >
          {showSearch && (
            <div className="control control-focusless">
              <SVG src="/images/icon-search.svg" />
              <input
                type="text"
                placeholder={`Find ${filterLabels[item.key].toLowerCase()}`}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
          )}

          {!isBlank(shownOptions) && (
            <List
              width={300} // see .nav-filter-menu in static/stylesheets/publisher/filter.scss
              height={Math.min(245, listHeight)}
              rowHeight={33}
              rowCount={shownOptionKeys.length}
              style={{
                padding: 6,
                paddingRight: listHeight > 245 ? 0 : 6,
              }}
              rowRenderer={(props) => (
                <NavSelectItem
                  key={props.key}
                  item={item}
                  options={shownOptions}
                  optionKeys={shownOptionKeys}
                  onSelect={onSelect}
                  setOpen={setOpen}
                  isMultipleChoice
                  {...props}
                />
              )}
            />
          )}
          {isBlank(shownOptions) && (
            <div
              className="text-light text-bold text-center inactive p-y-2 m-y-15"
              style={{ width: 300 }}
            >
              Nothing found
            </div>
          )}

          {!isMultipleChoice && showRemove && (
            <button
              className="remove-btn link"
              onClick={() => {
                onRemove(index, item.key)
                setOpen(false)
              }}
            >
              <SVG src="/images/icon-trash.svg" className="m-r-2" />
              Remove filter
            </button>
          )}
        </div>
      )}
    </div>
  )
}

const NavSelectItem = (props) => {
  const {
    item,
    options,
    optionKeys,
    onSelect,
    setOpen,
    index,
    style,
    isMultipleChoice,
  } = props
  const [isSelected, setIsSelected] = useState(false)

  const value = optionKeys[index]
  const label = options[value]

  useEffect(() => {
    const isSelected = item.value.split(', ').some((v) => v === value)
    setIsSelected(isSelected)
  }, [item.value, value])

  return (
    <div
      title={toUnicode(label)}
      className={varClass({
        'nav-filter-item': true,
        'selected': isSelected,
      })}
      style={style}
      onClick={() => {
        onSelect(item.key, value, toUnicode(label))
        setOpen(!!isMultipleChoice)
      }}
    >
      {toUnicode(label)}
    </div>
  )
}

const NavInput = (props) => {
  const {
    index,
    item,
    filterLabels,
    onSelect,
    onRemove,
    hideRemove,
    hideRemoveOnlyWithValue,
    isMultipleChoice,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [value, setValue] = useState(item.value || '')
  const [valueTimeout, setValueTimeout] = useState<TTimeout | null>(null)
  useEffect(() => {
    if (valueTimeout) {
      clearTimeout(valueTimeout)
    }
    setValueTimeout(
      setTimeout(() => {
        setValueTimeout(null)
        onSelect(item.key, value)
      }, 750)
    )
  }, [value])

  const hasValue = item.value || item.label

  const showRemove = shouldShowRemove(item, hideRemove, hideRemoveOnlyWithValue)

  return (
    <div className="nav-filter" ref={ref}>
      <button
        className={varClass({
          'nav-filter-button': true,
          [`nav-filter-button-${item.key}`]: true,
          'open': open,
          'active': !!hasValue,
        })}
        onClick={() => setOpen(!open)}
      >
        {filterLabels[item.key]}:{' '}
        <b>
          {hasValue ? (
            <WithTooltip
              className="nav-filter-value"
              label={item.label || item.value}
              text={item.label || item.value}
            />
          ) : (
            'All'
          )}
        </b>
        <SVG
          src="/images/chevron-down.svg"
          className={varClass({
            'm-l-30': true,
            'rotate-180': open,
          })}
        />
      </button>

      {hasValue && showRemove && (
        <button
          className="nav-filter-remove"
          onClick={() => {
            onRemove(index, item.key)
            setOpen(false)
          }}
        >
          <SVG src="/images/insights/icon-trash.svg" />
        </button>
      )}

      <div
        className={varClass({
          'nav-filter-menu': true,
          'open': open,
        })}
      >
        <div className="control control-focusless input">
          <SVG src="/images/icon-search.svg" />
          <input
            type="text"
            placeholder={`Find ${filterLabels[item.key]}`}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        </div>

        {showRemove && (
          <button
            className="remove-btn link"
            onClick={() => {
              onRemove(index, item.key)
              setOpen(false)
            }}
          >
            <SVG src="/images/icon-trash.svg" className="m-r-2" />
            Remove filter
          </button>
        )}
      </div>
    </div>
  )
}

const NavPair = (props) => {
  const {
    index,
    item,
    filterLabels,
    onSelect,
    onRemove,
    hideRemove,
    hideRemoveOnlyWithValue,
    isMultipleChoice,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [initialKey, initialValue] = useMemo(() => {
    if (item.value) {
      return item.value.split(' = ')
    } else {
      return []
    }
  }, [])

  const [key, setKey] = useState(initialKey || '')
  const [value, setValue] = useState(initialValue || '')
  const [valueTimeout, setValueTimeout] = useState<TTimeout | null>(null)
  useEffect(() => {
    if (valueTimeout) {
      clearTimeout(valueTimeout)
    }
    setValueTimeout(
      setTimeout(() => {
        setValueTimeout(null)
        const result = [key, value].filter((str) => !!str).join(' = ')
        onSelect(item.key, result)
      }, 500)
    )
  }, [key, value])

  const hasValue = item.value || item.label

  const showRemove = shouldShowRemove(item, hideRemove, hideRemoveOnlyWithValue)

  return (
    <div className="nav-filter" ref={ref}>
      <button
        className={varClass({
          'nav-filter-button': true,
          [`nav-filter-button-${item.key}`]: true,
          'open': open,
          'active': !!hasValue,
        })}
        onClick={() => setOpen(!open)}
      >
        {filterLabels[item.key]}:{' '}
        <b>
          {hasValue ? (
            <WithTooltip
              className="nav-filter-value"
              label={item.label || item.value}
              text={item.label || item.value}
            />
          ) : (
            'All'
          )}
        </b>
        <SVG
          src="/images/chevron-down.svg"
          className={varClass({
            'm-l-15': true,
            'rotate-180': open,
          })}
        />
      </button>

      {hasValue && showRemove && (
        <button
          className="nav-filter-remove"
          onClick={() => {
            onRemove(index, item.key)
            setOpen(false)
          }}
        >
          <SVG src="/images/insights/icon-trash.svg" />
        </button>
      )}

      <div
        className={varClass({
          'nav-filter-menu': true,
          'open': open,
        })}
      >
        <div className="control control-focusless input pair">
          <SVG src="/images/icon-search.svg" />
          <input
            type="text"
            placeholder={`Find ${filterLabels[item.key]}`}
            value={key}
            onChange={(event) => setKey(event.target.value)}
          />
          <span>=</span>
          <input
            type="text"
            placeholder="Value"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        </div>

        {showRemove && (
          <button
            className="remove-btn link"
            onClick={() => {
              onRemove(index, item.key)
              setOpen(false)
            }}
          >
            <SVG src="/images/icon-trash.svg" className="m-r-2" />
            Remove filter
          </button>
        )}
      </div>
    </div>
  )
}

const NavAdd = (props) => {
  const { filterLabels, filterOptions, onAdd } = props

  const [open, setOpen] = useState(false)
  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div className="nav-filter nav-filter-add" ref={ref}>
      <button
        className={varClass({
          'nav-filter-button': true,
          'open': open,
        })}
        onClick={() => setOpen(!open)}
      >
        <SVG src="/images/icon-add.svg" className="m-r-15" />
        Filter
      </button>
      <div
        className={varClass({
          'nav-filter-menu': true,
          'open': open,
        })}
      >
        <ul className="overflow-vertical">
          {map(Object.keys(filterOptions), (key) => (
            <li
              key={key}
              onClick={() => {
                onAdd(key)
                setOpen(false)
                setTimeout(() => {
                  const element: HTMLElement | null = document.querySelector(
                    `.nav-filter-button-${key}`
                  )
                  element?.click()
                }, 30)
              }}
            >
              {filterLabels[key]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const NavClearFilter = (props) => {
  const { onClear, filters } = props
  return (
    <div className="nav-filter nav-clear-filter">
      <button
        className={varClass({
          'nav-clear-filter-button': true,
        })}
        onClick={() => onClear()}
      >
        Clear all
      </button>
    </div>
  )
}
