import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import {
  format,
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import SVG from 'react-inlinesvg'
import { useOutsideClick } from '../hooks'
import { applicationContext } from '../contexts'
import {
  varClass,
  saveValue,
  loadDateValue,
  removeValue,
  dateDiff,
  indexOfElement,
} from '../helpers'
import moment from 'moment'

const rangeKey = 'date-picker-range'
const prevRangeKey = 'date-picker-range-prev'
const startDateKey = 'datePickerRangeStart'
const endDateKey = 'datePickerRangeEnd'
const startPrevDateKey = 'datePickerPrevStart'
const endPrevDateKey = 'datePickerPrevEnd'
const lastDateKey = 'datePickerRangeLast'

interface DatePickerState {
  range: {
    startDate: Date
    endDate: Date
    key: string
  }
  prevRange: {
    startDate: Date
    endDate: Date
    key: string
  }
  setRange: (newRange: { startDate: Date; endDate: Date; key: string }) => void
  setPrevRange: (newRange: {
    startDate: Date
    endDate: Date
    key: string
  }) => void
  prevRangeEnabled: boolean
  setPrevRangeEnabled: (value: boolean) => void
}

const getDefineds = () => ({
  startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOf4DaysAgo: startOfDay(addDays(new Date(), -4)),
  endOf4DaysAgo: endOfDay(addDays(new Date(), -4)),
  startOf5DaysAgo: startOfDay(addDays(new Date(), -5)),
  endOf5DaysAgo: endOfDay(addDays(new Date(), -5)),
  startOf30DaysAgo: startOfDay(addDays(new Date(), -30)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  startOf12MonthsAgo: startOfDay(addMonths(new Date(), -12)),
})

const getStaticRanges = (enableCompare: boolean) => {
  const defineds = getDefineds()
  return createStaticRanges(
    [
      {
        label: 'Today',
        range: () => ({
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: 'Yesterday',
        range: () => ({
          startDate: defineds.startOfYesterday,
          endDate: defineds.endOfYesterday,
        }),
      },
      {
        label: 'This week',
        range: () => ({
          startDate: defineds.startOfWeek,
          endDate: defineds.endOfWeek,
        }),
      },
      {
        label: 'Last week',
        range: () => ({
          startDate: defineds.startOfLastWeek,
          endDate: defineds.endOfLastWeek,
        }),
      },
      {
        label: 'Last 30 days',
        range: () => ({
          startDate: defineds.startOf30DaysAgo,
          endDate: defineds.endOfToday,
        }),
      },
      enableCompare
        ? {
            label: 'Compare to last 30 days',
            range: () => ({
              startDate: defineds.startOf4DaysAgo,
              endDate: defineds.endOfYesterday,
              prevStartDate: defineds.startOf30DaysAgo,
              prevEndDate: defineds.endOf5DaysAgo,
            }),
          }
        : undefined,
      {
        label: 'This month',
        range: () => ({
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfMonth,
        }),
      },
      {
        label: 'Last month',
        range: () => ({
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth,
        }),
      },
      {
        label: 'Month to date',
        range: () => ({
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: 'Year to date',
        range: () => ({
          startDate: defineds.startOfYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: 'Last 12 months',
        range: () => ({
          startDate: defineds.startOf12MonthsAgo,
          endDate: defineds.endOfToday,
        }),
      },
    ].filter((v) => v)
  )
}

export const useDatePickerState = () => {
  const { datePickerState } = useContext<any>(applicationContext)
  return datePickerState
}

export const newDatePickerState = () => {
  const lastDateSave = loadDateValue(lastDateKey)
  if (!lastDateSave || lastDateSave.getDate() !== new Date().getDate()) {
    removeValue(startDateKey)
    removeValue(endDateKey)
    removeValue(startPrevDateKey)
    removeValue(endPrevDateKey)
    removeValue(lastDateKey)
  }

  const defineds = getDefineds()

  const [range, setRange] = useState({
    startDate: loadDateValue(startDateKey, defineds.startOfMonth),
    endDate: loadDateValue(endDateKey, defineds.endOfMonth),
    key: rangeKey,
    color: '#0C74DC',
  })

  const [prevRange, setPrevRange] = useState(() => {
    let prevStartDate = loadDateValue(startPrevDateKey)
    let prevEndDate = loadDateValue(endPrevDateKey)
    if (!prevStartDate || !prevEndDate) {
      const prevDiff = dateDiff(range.startDate, range.endDate, 'days')
      prevEndDate = moment(range.startDate)
        .subtract(1, 'day')
        .endOf('day')
        .toDate()
      prevStartDate = moment(prevEndDate)
        .subtract(prevDiff, 'days')
        .startOf('day')
        .toDate()
    }
    return {
      startDate: prevStartDate,
      endDate: prevEndDate,
      key: prevRangeKey,
      color: '#FFB000',
    }
  })

  const [prevRangeEnabled, setPrevRangeEnabled] = useState(false)

  return {
    range,
    setRange,
    prevRange,
    setPrevRange,
    prevRangeEnabled,
    setPrevRangeEnabled,
  }
}

export const DatePicker = (props) => {
  const { className, enableCompare } = props

  const [open, setOpen] = useState(false)
  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })
  const rangeEditRef = useRef({
    loaded: false,
    tail: false,
    prev: false,
  }).current

  const {
    range,
    setRange,
    prevRange,
    setPrevRange,
    prevRangeEnabled,
    setPrevRangeEnabled,
  } = useDatePickerState()
  const displayState = newDatePickerState()
  const {
    range: displayRange,
    setRange: setDisplayRange,
    prevRange: prevDisplayRange,
    setPrevRange: setPrevDisplayRange,
  } = displayState

  const generateNextRange = () => {
    const diff = dateDiff(
      prevDisplayRange.startDate,
      prevDisplayRange.endDate,
      'days'
    )
    const startDate = moment(prevDisplayRange.endDate)
      .add(1, 'day')
      .startOf('day')
    const endDate = moment(startDate).add(diff, 'days').endOf('day')
    setDisplayRange({
      ...displayRange,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    })
  }
  const resetPrevRange = () => {
    if (!open) return

    const prevDiff = dateDiff(
      displayRange.startDate,
      displayRange.endDate,
      'days'
    )
    const prevEndDate = moment(displayRange.startDate)
      .subtract(1, 'day')
      .endOf('day')
    const prevStartDate = moment(prevEndDate)
      .subtract(prevDiff, 'days')
      .startOf('day')
    setPrevDisplayRange({
      ...prevDisplayRange,
      startDate: prevStartDate.toDate(),
      endDate: prevEndDate.toDate(),
    })
    return {
      startDate: prevStartDate,
      endDate: prevEndDate,
    }
  }

  const [displayCompare, setDisplayCompare] = useState(prevRangeEnabled)

  useEffect(() => {
    if (open === false) {
      setDisplayRange(range)
    }
  }, [open])

  useEffect(() => {
    if (!rangeEditRef.loaded) {
      rangeEditRef.loaded = true
      return
    }
    if (displayCompare) {
      resetPrevRange()
    }
  }, [displayCompare])

  useEffect(() => {
    if (!enableCompare && prevRangeEnabled) {
      setPrevRangeEnabled(false)
    }
  }, [enableCompare])

  useEffect(() => {
    if (displayCompare && rangeEditRef.tail) {
      if (displayRange.startDate <= prevDisplayRange.endDate) {
        if (rangeEditRef.prev) {
          generateNextRange()
        } else {
          resetPrevRange()
        }
      }
    }
  }, [displayCompare, displayRange, prevDisplayRange])

  const [nextSelectToSubmit, setNextSelectToSubmit] = useState(false)

  const handleSelect = (ranges) => {
    if (ref.current) {
      const editedInputEl = ref.current.querySelector(
        '.rdrDateDisplayItemActive'
      )
      if (editedInputEl) {
        rangeEditRef.prev =
          displayCompare && indexOfElement(editedInputEl.parentElement) === 1
        rangeEditRef.tail = indexOfElement(editedInputEl) === 1
      }
    }

    for (const key in ranges) {
      if (key === rangeKey) {
        setDisplayRange({ ...displayRange, ...ranges[key] })
      }
      if (key === prevRangeKey) {
        setPrevDisplayRange({ ...prevDisplayRange, ...ranges[key] })
      }
    }

    if (nextSelectToSubmit) {
      const key = Object.keys(ranges)[0]
      const range = ranges[key]
      if (range) {
        setNextSelectToSubmit(false)
        setRange({
          ...displayRange,
          startDate: range.startDate,
          endDate: range.endDate,
        })
        if (range.prevStartDate && range.prevEndDate) {
          setDisplayCompare(true)
          setPrevRangeEnabled(true)
          setPrevDisplayRange({
            ...prevDisplayRange,
            startDate: range.prevStartDate,
            endDate: range.prevEndDate,
          })
          setPrevRange({
            ...prevDisplayRange,
            startDate: range.prevStartDate,
            endDate: range.prevEndDate,
          })
        } else if (displayCompare) {
          const prevRange = resetPrevRange()
          setPrevRangeEnabled(true)
          setPrevRange({
            ...prevDisplayRange,
            startDate: prevRange.startDate,
            endDate: prevRange.endDate,
          })
        }
        saveValue(startDateKey, range.startDate)
        saveValue(endDateKey, range.endDate)
        saveValue(lastDateKey, new Date())
        setOpen(false)
      }
    }
  }

  const handleSubmit = () => {
    const startDate = moment(displayRange.startDate).startOf('day').toDate()
    const endDate = moment(displayRange.endDate).endOf('day').toDate()
    const prevStartDate = moment(prevDisplayRange.startDate)
      .startOf('day')
      .toDate()
    const prevEndDate = moment(prevDisplayRange.endDate).endOf('day').toDate()
    setRange({ ...displayRange, startDate, endDate })
    setPrevRange({
      ...prevDisplayRange,
      startDate: prevStartDate,
      endDate: prevEndDate,
    })
    setPrevRangeEnabled(displayCompare)
    saveValue(startDateKey, startDate)
    saveValue(endDateKey, endDate)
    saveValue(startPrevDateKey, prevStartDate)
    saveValue(endPrevDateKey, prevEndDate)
    saveValue(lastDateKey, new Date())
    setOpen(false)
  }

  useEffect(() => {
    if (ref.current) {
      ref.current
        .querySelector('.rdrStaticRanges')
        .addEventListener('click', (event) => {
          if (event.target.closest('.rdrStaticRange')) {
            setNextSelectToSubmit(true)
          }
        })
    }
  }, [ref])

  const staticRanges = getStaticRanges(enableCompare)

  const currentRange = useMemo(() => {
    for (const staticRange of staticRanges) {
      if (
        staticRange.isSelected(displayRange) &&
        (!staticRange.label.startsWith('Compare') || prevRangeEnabled)
      ) {
        return staticRange.label
      }
    }
    const startDate = format(displayRange.startDate, 'dd / MM / yyyy')
    const endDate = format(displayRange.endDate, 'dd / MM / yyyy')
    if (startDate === endDate) {
      return startDate
    } else {
      return `${startDate} - ${endDate}`
    }
  }, [displayRange, prevRangeEnabled])

  return (
    <div
      ref={ref}
      className={varClass({
        'date-picker-control': true,
        'compare': displayCompare,
        'open': open,
        [className]: !!className,
      })}
    >
      <div className="input" onClick={() => setOpen(!open)}>
        <div>
          <SVG src="/images/icon-calendar.svg" className="icon" />
          <span>{currentRange}</span>
        </div>
        <div>
          <SVG src="/images/chevron-right.svg" className="chevron" />
        </div>
      </div>
      <DateRangePicker
        ranges={
          enableCompare && displayCompare
            ? [displayRange, prevDisplayRange]
            : [displayRange]
        }
        onChange={handleSelect}
        rangeColors={displayCompare ? ['#0C74DC', '#FFB000'] : ['#0C74DC']}
        staticRanges={staticRanges}
        weekdayDisplayFormat="EEEEEE"
        dateDisplayFormat="dd / MM / yyyy"
        weekStartsOn={1}
        headerContent={
          <div className="rdrExtraDate">
            {format(new Date(), 'eeee, MMM. d')}
          </div>
        }
        footerContent={
          <div className="rdrExtraFooter">
            {!!enableCompare && (
              <div className="rdrCompare">
                Compare{' '}
                <button
                  className={varClass({
                    'btn-toggle btn-toggle-orange': true,
                    'active': displayCompare,
                  })}
                  onClick={() => setDisplayCompare(!displayCompare)}
                />
              </div>
            )}
            <button
              className="rdrExtraApply btn btn-primary"
              onClick={handleSubmit}
            >
              Apply date
            </button>
          </div>
        }
      />
    </div>
  )
}
