import React, { useEffect, useRef, useState } from 'react'
import { capitalize, useOutsideClick, varClass } from '..'
import { postRequest } from '../../../services'
import ReactTooltip from 'react-tooltip'

const statusTypeLabels = {
  stage: 'Stage',
  partner: 'Partner level',
  deal: 'Deal type',
}

const statusLabels = {
  stage: {
    '': 'No status',
    'todo': 'To do',
    'inprogress': 'In progress',
    'notnow': 'Not now',
    'no': 'No',
    'complete': 'Completed',
  },
  partner: {
    '': '-',
    'premium': 'Premium',
    'affiliate': 'Affiliate',
    'enterprise': 'Enterprise',
  },
  deal: {
    '': '-',
    'mrr': 'MRR',
    'enterprise': 'Enterprise',
    'advertisement': 'Advertisement',
  },
}

export const AdvertiserStatus = (props) => {
  const {
    advertiserUuid,
    advertiserDomain,
    statusType,
    initialStatus,
    tooltipId,
  } = props

  const [status, setStatus] = useState(initialStatus)
  const [open, setOpen] = useState(false)
  const containerRef = useRef()

  useEffect(() => {
    if (open) {
      ReactTooltip.hide()
    }
  }, [open])

  useOutsideClick(containerRef, () => setOpen(false))

  const onChange = async (newStatus: string) => {
    const oldStatus = status
    setStatus(newStatus)
    setOpen(false)
    try {
      await postRequest(`/advs/v1/status`, {
        advertiserUuid,
        advertiserDomain,
        advertiserStatusType: statusType,
        advertiserStatus: newStatus,
      })
    } catch (e) {
      setStatus(oldStatus)
    }
  }

  if (!advertiserUuid && !advertiserDomain) {
    return null
  }

  const tooltipProps = {
    'data-tip': tooltipId && !open ? statusTypeLabels[statusType] : null,
    'data-for': tooltipId,
  }

  return (
    <div
      className={varClass({
        'merchant-status-select': true,
        'open': open,
      })}
      ref={containerRef}
      {...tooltipProps}
    >
      <span
        className={varClass({
          'badge badge-hoverable text-smaller text-nowrap m-05': true,
        })}
        onClick={() => setOpen(!open)}
      >
        {statusLabels[statusType][status] || capitalize(status)}
      </span>
      <ul>
        {Object.keys(statusLabels[statusType]).map((newStatus) =>
          status || newStatus ? (
            <li key={newStatus}>
              <button className="link" onClick={() => onChange(newStatus)}>
                {newStatus
                  ? statusLabels[statusType][newStatus] || capitalize(newStatus)
                  : 'Clear'}
              </button>
            </li>
          ) : null
        )}
      </ul>
    </div>
  )
}
